import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
	Modal,
	Alert,
} from 'react-bootstrap';
import Table from '../../Objects/Table';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';
import { Decimal } from 'decimal.js';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [total_price, setTotalPrice] = useState(0);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [changePiece, setChangePiece] = useState(null);
	const [changePreOrder, setChangePreOrder] = useState(null);

	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.Cart.Query();
				
				//データセット
				setData(ref.items);

				//合計
				let total_price = new Decimal(0);
	
				for(const item of ref.items){
					const sub_price = new Decimal(item.price).times(item.piece);
					total_price = total_price.plus(sub_price);
				}
				
				//保存
				setTotalPrice(total_price.toNumber());
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	useEffect(() => {

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
		
	const handleDelete = async (item_id) => {

		//Formをロック
		setLock(true);
		
		try {
			//削除
			const ref = await API.Cart.Delete(item_id);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setChangePiece(null);
		setChangePreOrder(null);
		setAlert([]);
		setReload(reload+1);
	};
	
	const handlePieceChange = (e) => {
		const value = e.target.value;

		// 状態を更新
		setChangePiece((prevState) => ({
			...prevState,
			piece: Number(value), // 入力値を数値に変換して更新
		}));
	};
	
	const handlePieceChangeSubmit = async (item_id, piece, minimum_unit) => {

		//Formをロック
		setLock(true);

		try {

			if(Number(piece) < Number(minimum_unit)){
				piece = minimum_unit;
			}
					
			if(Number(piece) < Number(minimum_unit) || Number(piece) % Number(minimum_unit) !== 0){
				throw new Error('数量は' + minimum_unit + 'の単位で指定してください。');
			}

			//更新
			const params ={
				item_id : item_id,
				piece : Number(piece),
			};

			//更新
			const ref = await API.Cart.Update(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			//throw new Error('更新しました。');
			setReload(reload+1);

			//初期化
			setChangePiece(null);

			//完了通知
			setAlert(['更新しました。']);
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);

	};

	const handlePreOrderChange = (e) => {
		const value = e.target.value;

		// 状態を更新
		setChangePreOrder((prevState) => ({
			...prevState,
			pre_order_request: Number(value), // 入力値を数値に変換して更新
		}));
	};
	
	const handlePreOrderChangeSubmit = async (item_id, pre_order_request) => {

		//Formをロック
		setLock(true);

		console.log(item_id, pre_order_request);
		
		try {

			//更新
			const params ={
				item_id : item_id,
				pre_order_request : pre_order_request ? 1 : 0,
			};

			//更新
			const ref = await API.Cart.Update(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			//throw new Error('更新しました。');
			setReload(reload+1);

			//初期化
			setChangePreOrder(null);

			//完了通知
			setAlert(['更新しました。']);
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);

	};
	
	const formatItemLink = (cell, row) => {
		return (
			<div>
				<a href={'/item/' + row.item_id}>{cell}</a>
			</div>
		);
	};

	/*
	const formatPreOrder = (cell, row) => {
		return (
			<div className="text-right">
				{cell ? '×' : '○'}
				　
				<Button variant="dark" type="button" onClick={() => setChangePreOrder(row)} disabled={lock} >
					注残変更
				</Button>　
			</div>
		);
	};
	*/
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatPiece = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 個
				　
				<Button variant="dark" type="button" onClick={() => setChangePiece(row)} disabled={lock} >
					個数変更
				</Button>　
			</div>
		);
		//　[ <a href={'#' + row.item_id} onClick={() => handlePieceChange(row.item_id, Number(cell + row.minimum_unit), row.minimum_unit)} disabled={lock}>＋</a> | <a href={'#' + row.item_id} onClick={() => handlePieceChange(row.item_id, Number(cell - row.minimum_unit), row.minimum_unit)} disabled={lock}>－</a> ]
	};
	
	const formatSubPrice = (cell, row) => {
		const sub_price = Number(row.price) * Number(row.piece);
		return (
			<div className="text-right">
				{Number(sub_price ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatDelete = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => handleDelete(row.item_id)} disabled={lock} >
					削除
				</Button>
			</div>
		);
	};
	
	const columns = [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'minimum_unit',
			text: '最少出荷単位',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		/* Ver.3.30
			{
				dataField: 'pre_order_flag',
				text: '注残',
				sort: true,
				headerStyle:  { whiteSpace : 'nowrap' },
				style:  { whiteSpace : 'nowrap' },
				formatter: (cell) => cell ? '×' : '○',
			},
			{
				dataField: 'pre_order_request',
				text: '注残管理',
				sort: true,
				headerStyle:  { whiteSpace : 'nowrap' },
				style:  { whiteSpace : 'nowrap' },
				formatter: formatPreOrder,
			},
		*/
		{
			dataField: 'piece',
			text: '個数',
			sort: true,
			headerClasses: 'text-right',
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatPiece,
		},
		{
			dataField: 'dummy1',
			text: '合計金額',
			sort: true,
			headerClasses: 'text-right',
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatSubPrice,
		},
		{
			dataField: 'message',
			text: '備考',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'dummy2',
			text: '削除',
			sort: false,
			headerClasses: 'text-center',
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatDelete,
		},
	];

	const mobileColumns = [
		{
			dataField: 'item_id',
			text: '詳細',
			sort: true,
			formatter: (cell, row) => {
				return (
					<table width="100%">
						<tbody>
						<tr>
							<td>JAN</td>
							<td><a href={'/item/' + row.item_id}>{row.item_id}</a></td>
						</tr>
						<tr>
							<td>在庫</td>
							<td>{row.stock_flag}</td>
						</tr>
						<tr>
							<td>品番</td>
							<td>{row.product_no}</td>
						</tr>
						<tr>
							<td>品名</td>
							<td>{row.product_name}</td>
						</tr>
						<tr>
							<td>号数</td>
							<td>{row.gousu}</td>
						</tr>
						{/* Ver.3.30
							<tr>
								<td>注残</td>
								<td>{row.pre_order_flag ? '×' : '○'}</td>
							</tr>
							<tr>
								<td>注残管理</td>
								<td>{row.pre_order_request ? '×' : '○'}</td>
							</tr>
						*/}
						<tr>
							<td>価格</td>
							<td>{Number(row.price ?? 0).toLocaleString()} 円</td>
						</tr>
						<tr>
							<td>個数</td>
							<td>{Number(row.piece ?? 0).toLocaleString()} 個</td>
						</tr>
						<tr>
							<td>合計</td>
							<td>{Number(row.price * row.piece ?? 0).toLocaleString()} 円</td>
						</tr>
						<tr>
							<td colSpan="2">
								<Button variant="dark" type="button" onClick={() => setChangePiece(row)} disabled={lock} >
									個数変更
								</Button>　
								<Button variant="dark" type="button" onClick={() => setChangePreOrder(row)} disabled={row.pre_order_flag || lock} >
									注残変更
								</Button>　
								<Button variant="dark" type="button" onClick={() => handleDelete(row.item_id)} disabled={lock} >
									削除
								</Button>
							</td>
						</tr>
						</tbody>
					</table>
				);
			},
			headerStyle:  { display: 'none' },
		},
	];

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				
				<Modal show={changePiece !== null} onHide={handleClose} centered>
					<Modal.Body>
						<Form>
							<table className="table">
								<tbody>
								<tr>
									<td>JAN</td>
									<td>{changePiece?.item_id}</td>
								</tr>
								<tr>
									<td>在庫</td>
									<td>{changePiece?.stock_flag}</td>
								</tr>
								<tr>
									<td>品番</td>
									<td>{changePiece?.product_no}</td>
								</tr>
								<tr>
									<td>品名</td>
									<td>{changePiece?.product_name}</td>
								</tr>
								<tr>
									<td>号数</td>
									<td>{changePiece?.gousu}</td>
								</tr>
								<tr>
									<td>注残</td>
									<td>{changePiece?.pre_order_flag ? '×' : '○'}</td>
								</tr>
								<tr>
									<td>個数</td>
									<td>
										<Form.Control name={'piece'} type={'number'} step={changePiece?.minimum_unit} value={changePiece?.piece} onChange={handlePieceChange} size={'md'} required={true} />
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<Button variant="dark" type="button" onClick={() => handlePieceChangeSubmit(changePiece?.item_id, changePiece?.piece, changePiece?.minimum_unit)} disabled={lock} >
											更新
										</Button>
									</td>
								</tr>
								</tbody>
							</table>
						</Form>
					</Modal.Body>
				</Modal>

				<Modal show={changePreOrder !== null} onHide={handleClose} centered>
					<Modal.Body>
						<Form>
							<table className="table">
								<tbody>
								<tr>
									<td>JAN</td>
									<td>{changePreOrder?.item_id}</td>
								</tr>
								<tr>
									<td>在庫</td>
									<td>{changePreOrder?.stock_flag}</td>
								</tr>
								<tr>
									<td>品番</td>
									<td>{changePreOrder?.product_no}</td>
								</tr>
								<tr>
									<td>品名</td>
									<td>{changePreOrder?.product_name}</td>
								</tr>
								<tr>
									<td>号数</td>
									<td>{changePreOrder?.gousu}</td>
								</tr>
								<tr>
									<td>注残</td>
									<td>{changePreOrder?.pre_order_flag ? '×' : '○'}</td>
								</tr>
								<tr>
									<td>注残管理</td>
									<td>
									<Form.Control
										name={'pre_order_request'}
										as="select"
										value={changePreOrder?.pre_order_request}
										onChange={handlePreOrderChange}
										size={'md'}
										required={true}
										readOnly={changePreOrder?.pre_order_flag ? true : false}
										disabled={changePreOrder?.pre_order_flag ? true : false}
									>
									<option value={0}>○</option>
									<option value={1}>×</option>
									</Form.Control>
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<Button variant="dark" type="button" onClick={() => handlePreOrderChangeSubmit(changePreOrder?.item_id, changePreOrder?.pre_order_request)} disabled={changePreOrder?.pre_order_flag || lock} >
											更新
										</Button>
									</td>
								</tr>
								</tbody>
							</table>
						</Form>
					</Modal.Body>
				</Modal>

				<Row>
					<Col sm={12}>
						<h4>商品カート</h4>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }}>
					<Col sm={12}>
						<Badge pill variant="secondary">
							<h6>　<font color="white">合計 { Number(total_price ?? 0).toLocaleString() } 円</font>　</h6>
						</Badge>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }}>
					<Col sm={6}>
						<Form name="form">
							<Button variant="dark" type="button" block="true" onClick={() => { window.location.href='/order/'; } } disabled={lock} >発注手続きへ</Button>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'item_id'} data={data} columns={isMobile ? mobileColumns : columns} />
					</Col>
				</Row>
				<Alert variant="warning" style={{ marginTop: 20 }}>
					※在庫「○」の商品でも、ご注文手続の途中で欠品となる場合がございます。予めご了承ください。
				</Alert>
			</Container>
		);
	}
};

export default withRouter(Default);
